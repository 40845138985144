import { useEffect } from 'react';
import useAuth from 'src/hooks/use-auth';
import withAuth from 'src/lib/with-auth';
import LoginModule from 'src/modules/login/views';

const Login = () => {
  const { login } = useAuth();

  useEffect(() => {
    setTimeout(login, 500);
  }, []);

  return <LoginModule />;
};

export default withAuth(Login);
